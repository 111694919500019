// extracted by mini-css-extract-plugin
export var theTitle = "layout-module--theTitle--26V05";
export var container = "layout-module--container--YM7Lx";
export var titleDisplay = "layout-module--titleDisplay--14oZz";
export var containerTop = "layout-module--containerTop--1g_s-";
export var heading = "layout-module--heading--2By-2";
export var navLinks = "layout-module--nav-links--1VlO2";
export var navLinkItem = "layout-module--nav-link-item--31U8T";
export var navLinkText = "layout-module--nav-link-text--ExV28";
export var exampleTitle = "layout-module--example-title--3acof";
export var clickableCardsHolder = "layout-module--clickableCardsHolder--3zfWd";
export var clickableCard = "layout-module--clickableCard--2KYCA";
export var roundedImage = "layout-module--roundedImage--2aOyW";
export var tempRedBorder = "layout-module--temp-red-border--3joMP";
export var centerImageLayout = "layout-module--centerImageLayout--6dmO8";