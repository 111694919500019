import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import {
   faGithubSquare,
   faTwitter,
   faFacebook,
   faInstagram,
   faYoutube,
} from '@fortawesome/free-brands-svg-icons';
library.add(
   faCoffee,
   faGithubSquare,
   faTwitter,
   faFacebook,
   faInstagram,
   faYoutube
);

// https://www.newdevcity.com/gatsby-react-fontawesome-accessibility-icons-how-to
