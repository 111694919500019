import * as React from 'react';
import { Link } from 'gatsby';
import {
   container,
   containerTop,
   heading,
   navLinks,
   navLinkItem,
   navLinkText,
   clickableCardsHolder,
   clickableCard,
   roundedImage,
   titleDisplay,
   centerImageLayout,
} from './layout.module.css';
// import { StaticImage } from 'gatsby-plugin-image';
// import { GatsbyImage } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import Footer from './footer';

const Layout = ({ pageTitle, children }) => {
   return (
      <div className={container}>
         <div className={containerTop}>
            <title>{pageTitle}</title>
            <nav>
               <ul className={navLinks}>
                  <li className={navLinkItem}>
                     <Link to="/" className={navLinkText}>
                        Home
                     </Link>
                  </li>
                  <li className={navLinkItem}>
                     <Link to="/about" className={navLinkText}>
                        About
                     </Link>
                  </li>
                  <li className={navLinkItem}>
                     <Link to="/blog" className={navLinkText}>
                        Blog
                     </Link>
                  </li>
               </ul>
            </nav>
         </div>
         <main>
            <h1 className={heading}>{pageTitle}</h1>
            {children}
         </main>
         <Footer></Footer>
      </div>
   );
};

export default Layout;

// <div className={clickableCardsHolder}>
//    <div className={clickableCard}>
//       <div>
//          <StaticImage
//             alt="FirstImage"
//             // src="../images/img1.jpg"
//             src="../images/StJoseph.jpg"
//             // width="300"
//             // height="150"
//             // width="500"
//             // height="300"
//             width="700"
//             height="400"
//             // css={css`
//             //    border-radius: 14px;
//             // `}
//             // style="roundedImage"
//          />
//       </div>
//       <div>
//          <h2>Be in the bliss</h2>
//          <p>
//             Word word word word word word word. Word word word word
//             word word word.
//          </p>
//       </div>
//    </div>
//    <div className="clickableCard">
//       <div>
//          <StaticImage
//             alt="FirstImage"
//             // src="../images/img2.jpg"
//             src="../images/StJoseph.jpg"
//             // width="300"
//             // height="150"
//             // width="500"
//             // height="300"
//             width="700"
//             height="400"
//          />
//       </div>
//       <div>
//          <h2>Live in the light</h2>
//          {/* <h2>Live in the light</h2> */}
//          <p>
//             Word word word word word word word. Word word word word
//             word word word.
//          </p>
//       </div>
//    </div>
//    <div className="clickableCard">
//       <div>
//          <StaticImage
//             alt="FirstImage"
//             // src="../images/img3.jpg"
//             src="../images/StJoseph.jpg"
//             className="roundedImage"
//             // width="300"
//             // height="150"
//             // width="500"
//             // height="300"
//             width="700"
//             height="400"
//          />
//       </div>
//       <div>
//          <h2>Love + wonder</h2>
//          <p>
//             Word word word word word word word. Word word word word
//             word word word.
//          </p>
//       </div>
//    </div>
// </div>;
